<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="8">
                        <v-text-field
                            label="Nome"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nm_conselho"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4">
                        <v-select
                            label="Status"
                            outlined
                            dense
                            clearable
                            v-model="filtro.st_status"
                            :items="[
                                {value: true, text: 'Ativo'},
                                {value: false, text: 'Inativo'},
                            ]"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn v-if="$store.getters['auth/usuarioHasRole'](['Administrador'])"
                color="primary"
                :to="{name: 'conselho-novo'}"
            >
                Novo Registro
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >

            <template v-slot:item.conselho_pai="{ item }" >
                <conselhos-chip :conselhos="item.conselho_pai" />
            </template>

            <template v-slot:item.st_status="{ item }">
                <v-chip
                    v-if="item.st_status"
                    small
                    outlined
                    color="primary"
                >
                    Ativo
                </v-chip>
                <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                >
                    Inativo
                </v-chip>
            </template>
            <template v-if="$store.getters['auth/usuarioHasPermissao'](['ConselhoVisualizar'])" v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="cyan"
                            x-small
                            elevation="0"
                            :to="{name: 'conselho-visualizar', params: {conselho: item.id_conselho}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-magnify
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Visualizar</span>
                </v-tooltip>
                <v-tooltip v-if="$store.getters['auth/usuarioHasPermissao'](['ConselhoAtualizar'])" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'conselho-editar', params: {conselho: item.id_conselho}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

<!--                <DeleteAction v-if="$store.getters['auth/usuarioHasPermissao'](['ConselhoDeletar'])" @delete="deletar(item[column_id])"/>-->
                <ConditionalDeleteAction
                    v-if="$store.getters['auth/usuarioHasPermissao'](['ConselhoDeletar'])"
                    :showCheckButton="item.st_status ? '1' : null"
                    :emit="'delete'"
                    @delete="deletar(item[column_id],item.st_status)"
                />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {getConselhosPaginate, deleteConselho, getConselho, ativarInativarConselho} from "../../api/conselho";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import ConselhosChip from "../../components/inputs/ConselhosChip.vue";
import _ from "lodash";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import ConditionalDeleteAction from "../../components/inputs/ConditionalDeleteAction.vue";
import FilterService from "../../filters/filterService";

export default {
    name: "ConselhosListar",
    components: {ConditionalDeleteAction, DeleteAction, ConselhosChip },
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                nm_usuario: null,
                st_status: true,
            },
            loading: true,
            column_id: 'id_conselho',
            headers: [
                // { text: 'ID', value: 'id_conselho', sortable: false },
                { text: 'Nome', value: 'nm_conselho', sortable: false },
                { text: 'Sigla', value: 'sg_sigla', sortable: false },
                { text: 'E-mail', value: 'ds_email', sortable: false },
                { text: 'Pai', value: 'conselho_pai', sortable: false},
                { text: 'Status', value: 'st_status', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        }
    },
    methods: {
        limparFiltro() {
            this.filtro = {
                nm_usuario: null,
                st_status: null,
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar()
        },
        filtrar() {
            getConselhosPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        deletar(id, st_status) {
            let promise;
            let msg = 'Conselho ' + (!st_status ? 'Ativado' : 'Inativado') + ' com sucesso.';

            promise = ativarInativarConselho(id, {'st_status':!st_status});

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: msg,
                })
                this.$vuetify.goTo(0);
                this.filtrar();
            })
        },
        // deletar(id) {
        //
        //     getConselho(id).then((response) => {
        //         deleteConselho(id).then((response) => {
        //             this.items = this.items.filter(item => item[this.column_id] !== id)
        //             //this.$store.dispatch('alertas/hide');
        //             this.$store.dispatch('alertas/show', {
        //                 tipo: 'Sucesso',
        //                 titulo: response.data.success.message,
        //             });
        //             this.$vuetify.goTo(0);
        //         })
        //         .catch(error => this.handleError(error))
        //     })
        // },
        handleError(error) {

            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        }
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
}
</script>
