<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            label="Descrição"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.ds_pergunta_qualitativa"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :to="{name: 'pergunta-qualitativa-novo'}"
            >
                Novo Registro
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >
            <template v-slot:item.in_ativo="{ item }">
                <v-chip
                    v-if="item.in_ativo"
                    small
                    outlined
                    color="primary"
                >
                    Ativo
                </v-chip>
                <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                >
                    Inativo
                </v-chip>
            </template>
            <template v-if="$store.getters['auth/usuarioHasPermissao'](['PerguntaQualitativaListar'])" v-slot:item.actions="{ item }">
                <!-- Contêiner de ações com d-flex para garantir a linha -->
                <v-row class="d-flex align-center justify-start no-gutters" style="flex-wrap: nowrap;">

                    <!-- Edit Button -->
                    <v-col class="pa-0 mr-2" cols="auto">
                        <v-tooltip v-if="$store.getters['auth/usuarioHasPermissao'](['PerguntaQualitativaAtualizar'])" top>
                            <template v-slot:activator="{ on: tooltip}">
                                <v-btn
                                    fab
                                    color="success"
                                    x-small
                                    elevation="0"
                                    :to="{name: 'pergunta-qualitativa-editar', params: {perguntaQualitativa: item.id_pergunta_qualitativa}}"
                                    v-on="{ ...tooltip}"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </v-col>

                    <!-- Delete Button -->
                    <v-col class="pa-0 mr-2" cols="auto">
                        <DeleteAction
                            v-if="$store.getters['auth/usuarioHasPermissao'](['PerguntaQualitativaAtivarInativar'])"
                            @delete="deletar(item[column_id])"
                        />
                    </v-col>

                    <!-- ConditionalDeleteAction Button -->
                    <v-col class="pa-0 mr-2" cols="auto">
                        <ConditionalDeleteAction
                            v-if="$store.getters['auth/usuarioHasPermissao'](['PerguntaQualitativaAtivarInativar'])"
                            :showCheckButton="item.in_ativo"
                            :emit="'activate-deactivate'"
                            @activate-deactivate="ativarInativar(item[column_id], item.in_ativo)"
                        />
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { getPerguntaQualitativaPaginate, excluirPerguntaQualitativa, ativarInativarPerguntaQualitativa } from "../../api/pergunta_qualitativa";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import _ from "lodash";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import ConditionalDeleteAction from "../../components/inputs/ConditionalDeleteAction.vue";
import FilterService from "../../filters/filterService";

export default {
    name: "PerguntaQualitativaListar",
    components: { DeleteAction, ConditionalDeleteAction},
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                ds_pergunta_qualitativa: null,
            },
            loading: true,
            column_id: 'id_pergunta_qualitativa',
            headers: [
                { text: 'Nome', value: 'ds_pergunta_qualitativa', sortable: false },
                { text: 'Ordem', value: 'order_pergunta', sortable: false, width: '100px' },
                { text: 'Status', value: 'in_ativo', sortable: false, width: '100px' },
                { text: 'Ações', value: 'actions', sortable: false, width: '120px' },
            ]
        }
    },
    methods: {
        limparFiltro() {
            this.filtro = {
                ds_pergunta_qualitativa: null,
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar()
        },
        filtrar() {
            getPerguntaQualitativaPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                }).catch(error => this.handleError(error));
        },
        deletar(id) {
            let promise;
            let msg = 'Pergunta Qualitativa excluída com sucesso.';

            promise = excluirPerguntaQualitativa(id);

            promise.then(response => {
                if (response.data.data === true) {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: msg,
                    })
                } else {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Erro',
                        titulo: 'Pergunta Qualitativa não Excluída, verifique se a mesma possui resposta(s) associada(s).',
                    })
                }
                this.$vuetify.goTo(0);
                this.filtrar();
            })
        },
        ativarInativar(id, in_ativo) {
            let promise;
            let msg = 'Pergunta ' + (!in_ativo ? 'Ativada' : 'Inativada') + ' com sucesso.';

            promise = ativarInativarPerguntaQualitativa(id, {'in_ativo':!in_ativo});

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: msg,
                })
                this.$vuetify.goTo(0);
                this.filtrar();
            })
        },
        handleError(error) {
            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        }
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
}
</script>
