<template >
    <v-menu :close-on-content-click="true">
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip}">
                    <v-btn
                        fab
                        :color="showCheckButton ? 'error' : 'success'"
                        elevation="0"
                        x-small
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...dialog }"
                    >
                        <v-icon>
                            {{ showCheckButton ? 'mdi-check' : 'mdi-check' }}
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ showCheckButton ? 'Inativar' : 'Ativar' }}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-subtitle class="text-center font-weight-medium">
                {{ showCheckButton ? 'Inativar' : 'Ativar' }}
            </v-card-subtitle>
            <v-card-text>
                {{ showCheckButton ? 'Tem certeza que deseja Inativar este registro?' : 'Tem certeza que deseja Ativar este registro?' }}
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="default">
                    Cancelar
                </v-btn>
                <v-btn
                    :color="showCheckButton ? 'error' : 'success'"
                    @click="confirmAction"
                >
                    {{ showCheckButton ? 'Confirmar' : 'Confirmado' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "DeleteAction",
    props: {
        showCheckButton: {
            type: String,
            default: null
        },
        emit: {
            type: String,
            required: true // A propriedade emit agora é obrigatória
        }
    },
    methods: {
        confirmAction() {
            // Emitir o evento com o nome da prop `emit` corretamente
            this.$emit(this.emit);
        }
    }
}
</script>
