var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form"},[_c('h1',{staticClass:"text-h5 my-4"},[_vm._v("\n            Cadastro de pergunta qualitativa\n        ")]),_vm._v(" "),_c('v-sheet',{staticClass:"pa-4 mb-5",attrs:{"rounded":"","outlined":""}},[_c('h2',{staticClass:"subtitle-1 mb-3"},[_vm._v("Dados da pergunta qualitativa")]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Descrição pergunta qualitativa *","outlined":"","dense":"","rules":_vm.validate('required', 'Descrição pergunta qualitativa'),"disabled":_vm.visualizar},model:{value:(_vm.form.ds_pergunta_qualitativa),callback:function ($$v) {_vm.$set(_vm.form, "ds_pergunta_qualitativa", $$v)},expression:"form.ds_pergunta_qualitativa"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-select',{attrs:{"label":"Tipo resposta *","outlined":"","dense":"","clearable":"","rules":_vm.validate('required', 'Tipo resposta'),"items":[
                            {value: 1, text: 'Única opção'},
                            {value: 2, text: 'Livre editável'},
                        ]},on:{"change":(event) => _vm.tipoChange(event)},model:{value:(_vm.form.tp_pergunta),callback:function ($$v) {_vm.$set(_vm.form, "tp_pergunta", $$v)},expression:"form.tp_pergunta"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"number","label":"Ordem","outlined":"","dense":"","rules":[
                                    _vm.validate('required', 'Ordem de visualização da Pergunta'),
                                    v => v >= 1 || 'O número deve ser maior ou igual a 1'
                                ]},model:{value:(_vm.form.order_pergunta),callback:function ($$v) {_vm.$set(_vm.form, "order_pergunta", $$v)},expression:"form.order_pergunta"}},'v-text-field',attrs,false),on))]}}])},[_vm._v(" "),_c('span',[_vm._v("A ordem de visualização da pergunta. Insira um número.")])])],1)],1),_vm._v(" "),(_vm.opcaoResposta)?_c('v-row',[_c('v-col',[_c('OpcaoRespostaQualitativa',{attrs:{"opcaoItens":this.opcoes},on:{"opcaoRespostaQualitativaEmit":_vm.opcaoRespostaQualitativaEmit}})],1)],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.voltar()}}},[_vm._v("\n                Voltar\n            ")]),_vm._v(" "),(!_vm.visualizar)?_c('v-btn',{class:{'opacity-50': _vm.form.order_pergunta < 1},attrs:{"color":"primary","disabled":!_vm.form.order_pergunta || _vm.form.order_pergunta < 1},on:{"click":function($event){return _vm.salvar()}}},[_vm._v("\n            Salvar\n            ")]):_vm._e()],1),_vm._v(" "),_c('v-dialog',{staticClass:"v-dialog",attrs:{"persistent":""},model:{value:(_vm.resposta_dialog),callback:function ($$v) {_vm.resposta_dialog=$$v},expression:"resposta_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("\n                    Aviso!\n                ")]),_vm._v(" "),_c('v-card-text',[_c('h3',[_vm._v("\n                        Ao menos duas opções de resposta devem ser preenchidas.\n                    ")])]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.resposta_dialog = false}}},[_vm._v("\n                        Fechar\n                    ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }